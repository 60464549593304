.social-container {
  background: #eee;
  padding: 25px 50px;
}
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;

}

a.social:hover {
  transform: translateY(-6px);
}

a.youtube {
  color: #eb3223;

}

a.facebook {
  color: #4167b2;
}

a.linkedin {
  color: #0077b6
}

a.github {
  color: #444444;
}

a.twitter {
  color: #1da1f3;
}

/*a {
}

a:hover {
  text-decoration: none;
}
*/

.profile-pic {
  height: 4vmin;
  margin-right: 10px;
  border-radius: 50px;
}

div.media {
  margin-top: 30px;
  margin-bottom: 10px;
}

div.media .media-heading .jobTotalDuration {
  color: #666;
  font-size: 14px;
  display: block;
}

div.media img {
  height: 10vmin;
  margin-right: 10px;
  //border-radius: 50px;
}

div.media .jobDuration {
  color: #444;
  display: block;
}

div.media .jobLocation {
  color: #666;
}

.formLabel {
  font-weight: bold;
}

.certificateLink {
  color: cornflowerblue;
}

footer {
  margin-top: 20px;
  margin-bottom: 10px;
  border-top: #444444;
  text-align: center;
}

footer a {
  color: black;
  font-size: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

